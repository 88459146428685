import "./button.css";

const Button = () => {
  return (
<button class="button">
     
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">JOIN NOW!</span>
    </span>
</button>
  )
};

export default Button;