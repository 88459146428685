import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { FaSquareXTwitter, FaTelegram } from "react-icons/fa6";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">

          
              <div className="social">
                <ul>
                  <li>
                    <a
                      target="blank"
                      href="https://twitter.com/robin_hoodcalls
"
                    >
                      <FaSquareXTwitter className="icons-footer" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="blank"
                      href="https://t.me/robinhoodpay_bot
"
                    >
                      <FaTelegram className="icons-footer" />
                    </a>
                  </li>
                </ul>
              </div>


        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; Robinhood Signals © 2023 - All rights reserved</p>
        </div>
      </div>
    </div>
  );
};
