import React from "react";
import robImage from "./rob.png";
import "./Header.css"; // Importe o arquivo CSS para estilização
import Button from "./button";


export const Header = (props) => {
  return (
    <header id="header header-background">
      <div className="overlay header-background">
        <div className="container mt-0">
          <div className="row pt-0">
          <div className="col-md-8 col-md-offset-2 intro-text">

            
              <h1>
                <img
                  src={robImage}
                  alt="Rob"
                  className="centered-image img-responsive animated-image"  
                />
                <script src="./animations.js"></script>
                <span></span>
              </h1>
              
              <h6>{props.data ? props.data.paragraph : "Loading"}</h6>
              <div className="text-center"> {/* Remova a classe d-flex */}
                <h7>We provide access to indicators and signals to be used in live roulette tables on online casino platforms such as Pragmatic and Evolution. With our signals, you can enhance your strategy, aiming to increase your profits and improve your success rate. Additionally, we offer educational materials that teach how to bet effectively</h7>
              </div>
              <div className="text-center"> {/* Adicione a classe text-center aqui */}
                <a target="_blank" href="https://t.me/robinhoodpay_bot">
                <Button />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};