import React from "react";
import "./price.scss"

export const Testimonials = (props) => {
  return (
    
    <div class="container1">
    <div class="column">
      <div class="pricing-card basic">
        <div class="pricing-header">
          <span class="plan-title"> PLAN PRICE</span>
          <div class="price-circle">
            <span class="price-title">
              <small>eth</small><span>0.12</span>
              
            </span>
            <div class="usd-price">
                $<span className="usd-value">200</span>
              </div>
              <span class="info">/ Month</span>
          </div>
        </div>
        <div class="badge-box">
        
        </div>
        <ul className="ulcard">
          <li>
            <strong>Members</strong> Telegrams
          </li>
          <li>
            <strong>Pragmatic</strong> Signals
          </li>
          <li>
            <strong>Evolution</strong> Signals
          </li>
          <li>
            <strong>24 Hours</strong> Support
          </li>
          <li>
            <strong>Beta Acess</strong> New Features
          </li>
        </ul>
        <div class="buy-button-box">
          <a href="https://t.me/robinhoodpay_bot" class="buy-now">BUY NOW</a>
        </div>        
      </div>
      
    </div> 
    
  </div>
  
  
  );
};
