import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>DEMO VIDEO</h2>
          <p>
          Video demonstration of how a member places bets while watching our signals.
          </p>
        </div>
        <div className="row">
  <div className="video-container">
    <video className="centered-video" src={require("./video.mp4")} controls></video>
  </div>
</div>
      </div>
    </div>
  );
};
